'use client'

import {
  RiChatHistoryLine,
  RiCloseFill,
  RiEqualizer3Line,
  RiHomeLine,
  RiSidebarFoldLine,
  RiSidebarUnfoldLine,
} from '@remixicon/react'
import { Button, Divider, Tooltip } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import companyLogo from 'public/companyLogo.png'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useSidebarState from '@/hooks/context/useSidebarState'
import useTourState from '@/hooks/context/useTourState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import { logoIndexClx, showIndexHeaderText } from '@/branding-config'
import { COMPANY } from '@/constants/env'
import { cn } from '@/utils/clsx'

import Conversations from './Conversations'
import ProfileBadge from './ProfileBadge'

interface ChatSidebarContentProps {
  isDrawer?: boolean
  adminChatbot?: boolean
}

const ChatSidebarContent: React.FC<ChatSidebarContentProps> = ({
  isDrawer,
  adminChatbot,
}) => {
  const pathname = usePathname()
  const { t } = useTranslation()
  const { close, setSelectedConversation } = useDrawerState()
  const { settings } = useDbSettings()
  const { user } = useAuth()
  const { userTourRef4 } = useTourState()
  const { minimized, toggleSidebar } = useSidebarState()

  const isDisabled = () => {
    if (!settings?.requireSubscription) {
      return false
    } else {
      return !user?.subscribed
    }
  }

  return (
    <div className='flex !h-screen flex-col justify-between gap-1 overflow-hidden p-1.5'>
      <div className='flex h-[calc(100%-62px)] flex-col gap-2'>
        {!adminChatbot && (
          <div className='flex w-full items-center justify-between p-2'>
            <Link
              href='/'
              onClick={() => pathname !== '/'}
              className='flex h-12 items-center gap-6'
            >
              <Image
                src={companyLogo}
                alt='company logo'
                className={cn(
                  'cursor-pointer',
                  logoIndexClx,
                  minimized && !isDrawer ? 'w-8 h-auto' : ''
                )}
              />
              {showIndexHeaderText && (!minimized || isDrawer) && (
                <div className='flex flex-col text-left'>
                  <span className='text-xl font-bold text-on-surface dark:text-dark-on-surface'>
                    {COMPANY}
                  </span>
                  <div className='gap-1 text-sm text-on-surface/60 dark:text-dark-on-surface/60'>
                    {t('index-header')}
                  </div>
                </div>
              )}
            </Link>
            {isDrawer ? (
              <Button
                type='text'
                onClick={close}
                icon={<RiCloseFill className='size-5' />}
              />
            ) : (
              !minimized && (
                <Button
                  type='text'
                  onClick={toggleSidebar}
                  icon={<RiSidebarFoldLine className='size-5' />}
                />
              )
            )}
          </div>
        )}
        <div ref={userTourRef4}>
          {!isDisabled() && (
            <>
              {user?.role === 'ADMIN' ? (
                <Tooltip
                  title={minimized ? t('admin-dashboard') : null}
                  placement='right'
                >
                  <Link href='/admin/dashboard' target='_blank'>
                    <Button
                      type='text'
                      className='flex w-full !justify-start'
                      icon={<RiEqualizer3Line className='size-5' />}
                    >
                      <span
                        className={cn(minimized && !isDrawer ? '!hidden' : '')}
                      >
                        {t('admin-dashboard')}
                      </span>
                    </Button>
                  </Link>
                </Tooltip>
              ) : (
                <Tooltip
                  title={minimized ? t('user-dashboard') : null}
                  placement='right'
                >
                  <Link href='/user/documents' target='_blank'>
                    <Button
                      type='text'
                      className='flex w-full !justify-start'
                      icon={<RiEqualizer3Line className='size-5' />}
                    >
                      <span
                        className={cn(minimized && !isDrawer ? '!hidden' : '')}
                      >
                        {t('user-dashboard')}
                      </span>
                    </Button>
                  </Link>
                </Tooltip>
              )}
            </>
          )}
        </div>
        <Tooltip
          title={minimized ? t('conversations-new') : null}
          placement='right'
        >
          <Button
            type='text'
            className='flex w-full !justify-start'
            onClick={() => {
              close()
              setSelectedConversation(undefined)
            }}
            icon={<RiHomeLine className='size-5' />}
          >
            <span className={cn(minimized && !isDrawer ? '!hidden' : '')}>
              {t('conversations-new')}
            </span>
          </Button>
        </Tooltip>
        {minimized && !isDrawer && (
          <Tooltip title={t('conversations')} placement='right'>
            <Button
              type='text'
              className='flex w-full !justify-start'
              onClick={toggleSidebar}
              icon={<RiChatHistoryLine className='size-5' />}
            >
              <span></span>
            </Button>
          </Tooltip>
        )}
        <Divider plain className='my-2' />
        {(!minimized || isDrawer) && <Conversations onClose={close} />}
      </div>
      <div className='flex flex-col items-center gap-2'>
        {minimized && !isDrawer && (
          <Button
            type='text'
            className='flex w-full !justify-start'
            onClick={toggleSidebar}
            icon={<RiSidebarUnfoldLine className='size-5' />}
          >
            <span></span>
          </Button>
        )}
        {!adminChatbot && <ProfileBadge minimized={minimized && !isDrawer} />}
      </div>
    </div>
  )
}

export default ChatSidebarContent
